.tb-r {
  border: 1px solid red;
}

.tb-b {
  border: 1px solid blue;
}

.tb-g {
  border: 1px solid green;
}

.test_ui_border {
  border: 1px solid darkgray;
  border-radius: 5px;
}

.main_c {
  color: var(--color-main-1);
}

.main_text {
  color: var(--color-gray-1);
}

.main_bg {
  background-color: var(--color-main-1);
}

.main_bd {
  border-color: var(--color-main-1);
}

.error_c {
  color: var(--color-point-3);
}

.error_bg {
  background-color: var(--color-point-3);
}

.error_bd {
  border-color: var(--color-point-3);
}

.shadow_1 {
  box-shadow: 1px 3px 17px 0px rgba(0, 40, 104, 0.18);
}

.shadow_2 {
  box-shadow: 0px 4px 4px 0px rgba(0, 40, 104, 0.1);
}

.shadow_selected {
  box-shadow: 0px 0px 5px 0px var(--color-main-1);
}

// 0.625
.project_layout {
  $header_height: 80px;
  .header_height {
    min-height: $header_height;
  }

  $navigator_size: 115px;
  .navigator_width {
    min-width: $navigator_size;
  }
  .navigator_item_height {
    min-height: $navigator_size;
  }

  $option_width: 400px;
  .option_width {
    min-width: $option_width;
    width: $option_width;
  }

  $controller_height: 100px;
  $avatar_editor_height: 350px;
  .project {
    .media_controller_height {
      min-height: $controller_height;
    }

    .avatar_editor_height {
      min-height: $avatar_editor_height;
    }
  }
}

/** Modal */
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-content {
  background: white;
  max-width: 95%;
  width: 500px;
  padding: 20px 40px 30px 40px;
  border-radius: 8px;
}

$tooltip_color: var(--color-main-1);
#tooltip {
  z-index: 10;
  position: absolute;
  left: -6px;
  top: 13px;
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;

  display: inline-block;
}

#tooltip.top {
  margin-top: -5px;
}

#tooltip.bottom {
  margin-top: 5px;
}

#tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: $tooltip_color;
}

#tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: $tooltip_color;
}
#tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $tooltip_color;
}

#tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label {
  max-width: 200px;
  padding: 1px 5px;
  color: #fff;
  text-align: center;
  background-color: $tooltip_color;
  border-radius: 4px;
}

/** 드래그 방지 */

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointerNone {
  pointer-events: none !important;
}
/** Scroll bar */

::-webkit-scrollbar-track {
  border-radius: 3px !important;
  background-color: #ededed;
}

::-webkit-scrollbar {
  border-radius: 3px;
  width: 6px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ededed;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #b0c2e8;
}

.aivatar_scroll_wrapper {
  scrollbar-gutter: stable;
}
