@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .box {
    @apply /*p-[100px]*/ /*bg-white*/ /*rounded-[50px]*/ /*shadow;*/ flex min-w-[360px] flex-col items-center;
  }

  .box-center {
    @apply box absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform;
  }

  .links-center {
    @apply absolute bottom-0 left-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-row items-center;
  }

  .box .title {
    @apply text-gray-2 mt-[50px] text-center text-[24px] font-medium;
  }

  .box .subtitle {
    @apply mt-[10px] whitespace-pre break-keep text-center text-[gray-500];
  }

  .input {
    @apply border-gray-3 placeholder:text-gray-3 h-[50px] w-full rounded-[10px] border px-[10px] outline-0 hover:border-black;
  }

  .input-error {
    @apply border-point-3 text-point-3 hover:border-point-3 border-[2px] shadow-[0px_0px_6px_0px_#FF5757];
  }

  .btn {
    @apply border-gray-3 flex h-[60px] items-center justify-center whitespace-nowrap rounded-[50px] border bg-white px-[32px] hover:opacity-70;
  }

  .btn-white {
    @apply btn border-main text-main border-[2px];
  }

  .btn-main {
    @apply btn bg-main border-none text-white;
  }

  .btn-main-3 {
    @apply btn bg-main-3 text-main border-none;
  }

  .btn-gray-5 {
    @apply btn bg-gray-5 text-gray-2 border-none;
  }

  .btn-gray-6 {
    @apply btn bg-gray-6 text-gray-3 border-none;
  }

  .btn-point-3 {
    @apply btn bg-point-3 hover:bg-point-4 hover:text-point-3 border-none text-white;
  }

  .btn-white,
  .btn-main,
  .btn-main-3,
  .btn-gray-5,
  .btn-gray-6 {
    @apply hover:bg-main-2 hover:border-transparent hover:text-white hover:opacity-100;
  }

  .btn-l {
    @apply text-[23px] font-bold;
  }

  .btn-m {
    @apply text-[17px] font-bold;
  }

  .btn-s {
    @apply h-[31px] w-auto px-[15px] text-[14px] font-medium;
  }

  .link {
    @apply text-gray-21 text-[14px] underline hover:opacity-70;
  }

  .link-main {
    @apply link text-hyperlink;
  }

  .link-bottom {
    @apply text-gray-21 text-[12px] font-[400] hover:opacity-70;
  }

  .table {
    @apply w-full;
  }

  .table th {
    @apply bg-main-3 !text-gray-3 box-content py-[18px];
  }

  .table th:first-child {
    @apply rounded-l-full;
  }

  .table th:last-child {
    @apply rounded-r-full;
  }

  .table tr + tr {
    @apply border-main-3 border-t-[1px];
  }

  .table tr > *:first-child {
    @apply pl-[35px];
  }

  .table tr > *:last-child {
    @apply pr-[35px];
  }

  .table td {
    @apply text-gray-2 pb-[21px] pt-[24px];
  }

  .chip {
    @apply bg-main-3 text-main flex h-[31px] w-fit cursor-default items-center rounded-[50px] px-[10px] text-[14px] font-medium [&>svg]:ml-[4px];
  }

  .chip-green {
    @apply chip bg-[#edfff7] text-[#2db277];
  }

  .chip-red {
    @apply chip bg-point-4 text-point-3;
  }

  .chip-yellow {
    @apply chip bg-point-7 text-point-5;
  }
}

.MuiDialog-container:after {
  @apply !h-0;
}

.MuiDialog-root .MuiDialog-container {
  @apply bg-[#00000099];
}

.MuiDialog-root .MuiPaper-root {
  @apply box max-w-none rounded-[20px] px-[70px] py-[25px];
}

/** Fonts */

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Thin.ttf');
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/fonts/NotoSansKR-Black.ttf');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@layer base {
  html {
    font-family: NotoSansKR;
    color: #323232;
  }
}

@layer utilities {
  .typo-head1 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 700;
    font-size: 23px;
    line-height: 1.48;
  }
  .typo-head2 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 700;
    font-size: 17px;
    line-height: 1.48;
  }
  .typo-head3 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.48;
  }

  .typo-body1 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
  }
  .typo-body2 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }
  .typo-body3 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 350;
    font-size: 16px;
    line-height: 1.5;
  }
  .typo-caption1 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
  }
  .typo-caption2 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
  }
  .typo-caption3 {
    font-family: 'NotoSansKR', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.5;
  }
}
